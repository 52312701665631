export const KT_ACCESSIBILITY_KEYS = [
	'ArrowUp',
	'ArrowLeft',
	'ArrowDown',
	'ArrowRight',
	'Home',
	'End',
	'Escape',
	'Enter',
	'Tab',
];